// @ts-strict-ignore
import { ReactNode } from 'react'

import { Heading5, Heading6 } from '~/theme/utils/typography'

import { RestaurantUrl, RestaurantUrlTypeEnum } from '~/generated/graphql'
import { gt } from '~/locale'
import Link from '~/shared/atoms/Link'
import Map from '~/shared/molecules/Map'

import { ContactDetails, IconButton, MapContainer, Section, StyledIcon, WebsiteSection, Wrapper } from './ContactShards'

const getRestaurantUrlIconByType = (type: RestaurantUrlTypeEnum) => {
  switch (type) {
    case RestaurantUrlTypeEnum.Facebook:
      return 'facebook'
    case RestaurantUrlTypeEnum.Instagram:
      return 'instagram'
    case RestaurantUrlTypeEnum.Tiktok:
      return 'tiktok'
    case RestaurantUrlTypeEnum.TripAdvisor:
      return 'tripadvisor'
    case RestaurantUrlTypeEnum.Google:
      return 'google'
    default:
      return 'globe'
  }
}

const URL_TYPES_HIDDEN = [RestaurantUrlTypeEnum.Website]
const isUrlTypeVisible = ({ urlType }: { urlType: RestaurantUrlTypeEnum }) => !URL_TYPES_HIDDEN.includes(urlType)

const ContactView = ({
  latitude,
  longitude,
  address,
  phoneNumber,
  isMobile,
  urls,
}: {
  latitude?: number
  longitude?: number
  address: ReactNode
  phoneNumber: string
  isMobile: boolean
  urls: Pick<RestaurantUrl, 'url' | 'urlType'>[]
}) => {
  const Heading = isMobile ? Heading6 : Heading5
  const webUrl = urls.find(url => url.urlType === RestaurantUrlTypeEnum.Website)

  return (
    <Wrapper>
      {latitude && longitude && (
        <MapContainer>
          <Map items={[{ latitude: latitude, longitude: longitude }]} flexibleSize={true} />
        </MapContainer>
      )}
      <ContactDetails>
        <Section>
          <Heading>{address || gt.tp('NoInformation', '-')}</Heading>
        </Section>
        <Section>
          <StyledIcon icon='phone-outline' />
          <Heading>{phoneNumber || gt.tp('NoInformation', '-')}</Heading>
        </Section>
        <WebsiteSection hasAddress={!!webUrl}>
          <StyledIcon icon='globe' />
          <Heading>
            <Link href={webUrl?.url || ''}>{webUrl?.url || gt.tp('NoInformation', '-')}</Link>
          </Heading>
        </WebsiteSection>
        {urls.filter(isUrlTypeVisible).map(({ url, urlType }) => (
          <a key={url} href={url} target='_blank' rel='noopener noreferer noreferrer'>
            <IconButton icon={getRestaurantUrlIconByType(urlType)} />
          </a>
        ))}
      </ContactDetails>
    </Wrapper>
  )
}

export default ContactView
