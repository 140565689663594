// @ts-strict-ignore
import { useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { useMediaQuery } from 'react-responsive'

import { isEmpty } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { mediaQueryIsMobile } from '~/theme/utils/grid'
import { Heading5, Heading6 } from '~/theme/utils/typography'
import { dayjsToString, getTimeFromSlot } from '~/utils/graphqlDataFormatters'
import { runEcommerceAdd } from '~/utils/gtag'
import { imgProxyLoader } from '~/utils/imgproxy'

import { festivalModalProps } from '~/components/Festival/FERModal'
import CardActions from '~/components/Product/ProductCardActions'
import ProductModal from '~/components/Product/ProductModal'
import { getDayUsageVariables } from '~/components/Reservation/SlotSelectionPanel/SlotSelectionPanelUtils'
import RestaurantSlider from '~/components/Restaurant/RestaurantPage/RestaurantSlider'
import { SliderImg } from '~/components/Restaurant/RestaurantPage/RestaurantSlider/RestaurantSliderView'
import { DEFAULT_SLOT_COMPUTED, defaultSearchSettings, paths, RESERVABLE_TYPES } from '~/constants'
import {
  FerModalFragment,
  ReservableInput,
  RestaurantListElementFragment,
  useCreateDailyReservationMutation,
  useSlotsUsageQuery,
} from '~/generated/graphql'
import { gt } from '~/locale'
import Icon from '~/shared/atoms/Icon'
import { Like } from '~/shared/atoms/Like'
import Pill from '~/shared/atoms/Pill'
import Tooltip from '~/shared/atoms/Tooltip'
import { useModal } from '~/shared/molecules/Modal'
import { SearchSettings } from '~/shared/molecules/Search'
import TagBar from '~/shared/molecules/TagBar'

import SearchSlotsModal from '../SearchSlotsModal'
import { LeanReservable } from '../SearchSlotsModal/SearchSlotsModalTypes'
import { modalProps } from '../SearchSlotsModal/SearchSlotsModalUtils'

import {
  Arrow,
  LikeContainerWide,
  MainContentContainer,
  MenuIcon,
  MenuIconWrapper,
  NoSlotsBadge,
  NoSlotsBadgeText,
  NoSlotsText,
  NoSlotsWrapper,
  Photo,
  PhotoWrapper,
  PillContainer,
  ReservationsCount,
  RestaurantDescription,
  RestaurantName,
  SliderImageWrapper,
  TagContainer,
  TooltipWrapper,
  Wrapper,
} from './RestaurantCardShards'
import { CloseIcon, LoadingPills } from './RestaurantCardUtils'
import RestaurantMenu from './RestaurantMenu'

const { DAILY } = RESERVABLE_TYPES

export type RestaurantCardType = 'default' | 'daily' | 'FER'
interface RestaurantCardProps extends React.HTMLAttributes<HTMLDivElement> {
  restaurant: RestaurantListElementFragment
  slotParams?: SearchSettings
  selectedDate?: string
  subtitle?: string
  type?: RestaurantCardType
  ferData?: FerModalFragment
  hasModal?: boolean
  reservableInput?: ReservableInput
  withCarousel?: boolean
}

const renderSliderItem = (type: RestaurantCardType) => (image: SliderImg, restaurantName: string) => (
  <SliderImageWrapper type={type}>
    <Image
      loader={imgProxyLoader}
      src={image?.original || image?.preview}
      quality={80}
      alt={restaurantName}
      layout='fill'
      objectFit='cover'
      priority={true}
    />
  </SliderImageWrapper>
)

const RestaurantCardView = ({
  restaurant,
  slotParams: params = defaultSearchSettings({ defaultSlot: DEFAULT_SLOT_COMPUTED }),
  subtitle,
  type = 'default',
  ferData: FER,
  hasModal = false,
  reservableInput,
  withCarousel,
  ...props
}: RestaurantCardProps) => {
  const { tp, tnp } = gt
  const router = useRouter()
  const { id: restaurantId, name, slug, description, favorited } = restaurant
  const [showMenuModal, setShowMenuModal] = useState(false)
  const reservationsCount = 0
  const isMobile = useMediaQuery({ query: mediaQueryIsMobile })
  const modal = useModal()
  const { date, peopleCount } = params
  const dateString = date ? dayjsToString(date) : ''
  const { data: slotsUsageData, loading: slotsLoading } = useSlotsUsageQuery({
    skip: type !== 'daily' || !dateString,
    variables: getDayUsageVariables(dateString, reservableInput),
  })

  const slotsToShow = Array.from({ length: 5 }).map((_, i) => params?.slot + (i * 30 - 60))
  const availableSlots: number[] = slotsUsageData?.slots
    .filter(({ slot, sizes }) => slotsToShow.includes(slot) && peopleCount <= sizes.length)
    .map(({ slot }) => slot)
  const [submittingSlot, setSubmittingSlot] = useState<number | null>(null)
  const [isHovered, setIsHovered] = useState(false)
  const [create] = useCreateDailyReservationMutation()
  const dailyReservable = restaurant.reservables?.find(reservable => reservable.__typename === DAILY)
  const createDailyReservation = (e, slot) => {
    e.preventDefault()
    e.stopPropagation()
    setSubmittingSlot(slot)
    create({
      variables: {
        date: dateString,
        slot,
        peopleCount,
        restaurantId,
      },
      onCompleted: data => router.push(paths.checkoutChefsMenu(data.createDailyReservation.code)),
      onError: () => setSubmittingSlot(null),
    })

    runEcommerceAdd({
      reservableType: DAILY,
      reservableData: { restaurant, quantity: peopleCount, price: 0 },
    })
  }

  const openProductModal = () => {
    modal.open(festivalModalProps, <ProductModal FER={FER} type={RESERVABLE_TYPES.FER} handleClose={modal.close} />)
  }

  const sliderImages = useMemo(() => [...(FER?.imageFiles || []), ...restaurant.imageFiles], [FER?.imageFiles, restaurant.imageFiles])
  const renderSliderPhoto = useMemo(() => renderSliderItem(type), [type])
  const isFER = type === 'FER'
  const isDaily = type === 'daily'

  useEffect(() => {
    const pathEnabled = router.query?.restaurant === slug
    if (hasModal && pathEnabled) {
      openProductModal()
    }
  }, [type])

  return (
    <>
      <Wrapper withBorder horizontal={isDaily} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <CardActions
          onClick={hasModal ? openProductModal : undefined}
          href={
            FER?.externalSaleUrl || {
              pathname: paths.singleRestaurant({ slug }),
              query: router.query,
            }
          }>
          <MainContentContainer cardType={type} {...props}>
            <PhotoWrapper>
              <LikeContainerWide>
                <Like restaurantId={restaurantId} favorited={favorited} />
              </LikeContainerWide>
              {withCarousel ? (
                <RestaurantSlider
                  images={sliderImages}
                  restaurantName={restaurant.name}
                  withFullGallery={false}
                  withControls={false}
                  autoPlayInterval={1000}
                  autoPlay={isHovered}
                  renderItem={renderSliderPhoto}
                  autoHeight={false}
                />
              ) : (
                <Photo src={restaurant.imageFiles[0]?.preview} alt={name} loader={imgProxyLoader} layout='fill' />
              )}
            </PhotoWrapper>
            {isDaily && reservationsCount > 0 && (
              <ReservationsCount>
                {tnp('Restaurant', '{{ count }} reservation today', '{{ count }} reservations today', reservationsCount, {
                  count: reservationsCount,
                })}
              </ReservationsCount>
            )}
            <RestaurantName as={isMobile ? Heading6 : Heading5} fontWeight='semiBold'>
              {name}
            </RestaurantName>
            {isFER && (
              <TooltipWrapper
                onMouseEnter={() => setShowMenuModal(true)}
                onMouseLeave={() => setShowMenuModal(false)}
                onClick={e => {
                  setShowMenuModal(true)
                  e.stopPropagation()
                }}>
                <Tooltip
                  size={'big'}
                  placement={'auto'}
                  show={showMenuModal}
                  label={
                    <>
                      <CloseIcon
                        onClick={e => {
                          e.stopPropagation()
                          setShowMenuModal(false)
                        }}
                      />
                      <RestaurantMenu menus={FER.menus} forceLabels={FER.festivalEdition.forceLabels} />
                    </>
                  }
                  trigger={'programmatic'}>
                  <MenuIconWrapper onClick={e => e.preventDefault()}>
                    <MenuIcon icon='book-new' />
                  </MenuIconWrapper>
                </Tooltip>
              </TooltipWrapper>
            )}
            <RestaurantDescription fontWeight='regular'>{subtitle ?? description}</RestaurantDescription>
            <TagContainer>
              <TagBar
                variant={isDaily ? 'default' : 'plainText'}
                sections={['price']}
                maxBaseTagCount={5}
                size={'small'}
                restaurant={restaurant}
                reservableExtra={undefined}
              />
            </TagContainer>
            {isDaily && params.date && (
              <>
                {slotsLoading ? (
                  <LoadingPills />
                ) : !isEmpty(availableSlots) ? (
                  <PillContainer>
                    {slotsToShow?.map(slot => (
                      <Pill
                        key={slot}
                        loading={slot === submittingSlot}
                        disabled={!availableSlots.includes(slot)}
                        onClick={e => createDailyReservation(e, slot)}
                        text={getTimeFromSlot(slot)}
                      />
                    ))}
                  </PillContainer>
                ) : (
                  <NoSlotsWrapper>
                    <NoSlotsText>
                      {tp('Restaurant', 'No available slots for {{ time }}', { time: getTimeFromSlot(params.slot) })}
                    </NoSlotsText>
                    <NoSlotsBadge
                      onClick={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        modal.open(
                          modalProps,
                          <SearchSlotsModal
                            restaurant={restaurant}
                            reservableInput={reservableInput}
                            reservable={dailyReservable as LeanReservable}
                          />
                        )
                      }}>
                      <NoSlotsBadgeText>{tp('Restaurant', 'Search for available slots')}</NoSlotsBadgeText>
                      <Arrow>
                        <Icon icon='arrow' />
                      </Arrow>
                    </NoSlotsBadge>
                  </NoSlotsWrapper>
                )}
              </>
            )}
          </MainContentContainer>
        </CardActions>
      </Wrapper>
    </>
  )
}

export default RestaurantCardView
