import dynamic from 'next/dynamic'

import Loader from '~/shared/atoms/Loader'

import { LoaderContainer, MapWrapper } from './MapShards'

export interface MapItem {
  latitude: number
  longitude: number
  name?: string
  description?: string
}

export interface MapViewProps {
  items: Array<MapItem>
  flexibleSize?: boolean
}

const MapView = ({ items, flexibleSize = false }: MapViewProps) => {
  if (!items || items.length === 0) return null

  const MapContent = dynamic(() => import('./MapUtils'), {
    loading: () => (
      <LoaderContainer>
        <Loader color='gray' />
      </LoaderContainer>
    ),
    ssr: false,
  })
  return (
    <MapWrapper flexibleSize={flexibleSize} id='map'>
      <MapContent items={items} />
    </MapWrapper>
  )
}

export default MapView
